@import "./color.scss";


.login_wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    >.login_form{
        padding: 30px 60px;
        border-radius: 12px;
        min-width: 300px;
        background-color: #f7f7f7;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        >.logo{
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            >img{
                width: 140px;
            }
        }
        >.btn_wrap{
            margin-top: 30px;
            display: flex;
            justify-content: center;
           
        }
    }
}