@import "./color.scss";
.header_wrapper {
  padding: 10px;
  box-sizing: border-box;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .title {
    display: flex;
    align-items: center;
    column-gap: 20px;
    font-size: 24px;
    font-weight: 600;
    font-family: $heading_font;
    color: $hightlight_color;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      text-align: center;
      color: $card_bg_color;
      box-shadow: 0 3px 8.3px 0.7px rgba(209, 121, 118, 0.76);
      background: linear-gradient(90deg, #d660f7, rgb(251 121 116));
    }
  }
  > .user_profile {
    position: relative;
    > .logout_btn {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      position: absolute;
      top: 25px;
      right: 0px;
      font-size: 14px;
      font-weight: 500;
      background-color: $hightlight_color;
      font-family: $txt_font;
      border: 1px solid $hightlight_color;
      color: $card_bg_color;
     
      border-radius: 4px;
      >svg{
        margin-right: 6px;
      }
    }
    .auth_profile {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: $txt_font;
      color: $hightlight_color;
      column-gap: 10px;
      border-radius: 5px;
      > h2 {
        font-weight: 500;
        font-size: 14px;
      }
      > svg {
        font-size: 20px;
      }
    }
  }
}
