@import "./color.scss";

.layout_wrapper {
  background-color: $card_bg_color;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  .dahboard_sidebar {
    width: 280px;
    padding: 12px;
    box-sizing: border-box;
    background-color: $page_bg_color;
    color: $txt_color;
    height: 100%;
    > .logo {
      display: flex;
      // justify-content: center;
      width: 100%;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $hightlight_color;
      > img {
        max-width: 170px;
      }
    }
    > .nav_links {
      list-style: none;
      padding-left: 0px;
      font-size: 16px;
      margin-top: 40px;
      > li {
        padding: 10px;
        border-radius: 6px;
        box-sizing: border-box;
        font-family: $txt_font;
        margin-bottom: 20px;
        cursor: pointer;
        > a {
          color: $txt_color;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 1.5;
          > span {
            text-align: start;
          }
        }
        &:hover {
          background-color: $hightlight_color;
          > a {
            color: $card_bg_color;
          }
        }
      }
    }
  }
  .right_section{
    width: calc(100% - 290px);
    overflow-y: scroll;
  }
}
