@import "./color.scss";

.product_list {
  margin: 30px;
  .create_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .table_list {
    margin-bottom: 30px;
    .action_btn {
      display: flex;
      column-gap: 20px;
      button {
        outline: none;
        border: none;
        font-size: 20px;
        background-color: transparent;
        cursor: pointer;
        &.primary {
          color: $headline_color;
          border-bottom: 1px solid $headline_color;
        }
        &.danger {
          background-color: red;
          color: #fff;
          border-bottom: 1px solid red;
          padding: 4px 10px;
          border-radius: 4px;
          font-size: 14px;
          text-transform: capitalize;
          outline: none;
          border: none;
        }
        &.invoice {
          background-color: green;
          color: #fff;
          padding: 4px 10px;
          border-radius: 4px;
          font-size: 14px;
          text-transform: capitalize;
          outline: none;
          border: none;
        }
        &.status {
          background-color: $hightlight_color;
          color: #fff;
          padding: 4px 10px;
          border-radius: 4px;
          font-size: 14px;
          text-transform: capitalize;
          outline: none;
          border: none;
        }
      }
    }
  }
  .filter_section{
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
