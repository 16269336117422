.sales_card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  
    .card {
     
      padding: 15px;
      border-radius: 10px;
      color: #fff;
      font-weight: bold;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
      h3 {
        font-size: 16px;
        margin-bottom: 5px;
      }
  
      >div{
        display: flex;
        align-items: center;
       justify-content: space-between;
        margin-top: 15px;
        p {
            font-size: 20px;
            margin: 0;
          }
      
          svg {
            font-size: 40px;
          }
      }
    }
  }