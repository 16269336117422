@import "./color.scss";

.go_back {
  margin: 20px;
  > button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $hightlight_color;
    color: $card_bg_color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $hightlight_color;
    outline: none;
    box-shadow: none;
    &:hover {
      background-color: $card_bg_color;
      color: $hightlight_color;
    }
  }
}
.product_create {
  padding: 30px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  margin: 0px 20px;

  .input_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    // row-gap: ;
    .image_upload {
      display: block;
      > label {
        display: block;
        margin-bottom: 8px;
        color: $headline_color;
        font-size: 14px;
      }
      .selectImage {
        display: block;
        > input {
          margin-bottom: 10px;
        }
        > div {
          display: flex;
          align-items: center;
          column-gap: 20px;
          > img {
            width: 100px;
            height: 100px;
          }
          > button {
            background-color: $headline_color;
            color: $card_bg_color;
            font-size: 16px;
            border: 1px solid $headline_color;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            width: 30px;
            height: 30px;
          }
        }
      }

      > p {
        font-size: 14px;
      }
      > .image_preview {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 20px;

        > div {
          max-width: 43%;
          position: relative;
          > img {
            width: 100%;
            position: relative;
          }
          > button {
            position: absolute;
            right: 0;
            top: 0;
            background-color: red;
            color: $card_bg_color;
            font-size: 16px;
            border: 1px solid red;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .submit_btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
